import { Modal } from "react-responsive-modal";

interface ClaimModalProps {
  open: boolean;
  onCloseModal: () => void;
}

// ClaimModal.js
export const ClaimModal = (props: ClaimModalProps) => {
  return (
    <Modal
      classNames={{
        modal: "rounded-[4px] !p-[30px]",
      }}
      open={props.open}
      onClose={props.onCloseModal}
      center
    >
      <div className="text-[42px] text-[#666666] mb-[30px]">claim.</div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar
        risus non risus hendrerit venenatis. Pellentesque sit amet hendrerit
        risus, sed porttitor quam.
      </p>
    </Modal>
  );
};
