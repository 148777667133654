// Layout.js
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Layout = () => {
  return (
    <div className="mainContainer max-w-[1200px] min-h-[100dvh] mx-auto flex flex-col gap-[15px]">
      <Header />
      <Outlet />
      <Footer />
      <div className="bg-[#333333] -z-[5] opacity-[0.1] left-[50%] -ml-[25%] top-0 fixed w-[50%] h-[50vh] blur-[200px] rounded-full" />
      <div className="bg-[#009688] -z-[4] opacity-[0.2] left-[10%] top-[10%] fixed w-[400px] h-[400px] blur-[200px] rounded-full" />
      <div className="bg-[#009688] -z-[3] opacity-[0.2] right-[10%] top-[10%] fixed w-[200px] h-[200px] blur-[100px] rounded-full" />
      <div className="bg-[#2196f3] -z-[2] opacity-[0.2] right-[10%] bottom-[10%] fixed w-[400px] h-[400px] blur-[200px] rounded-full" />
      <div className="bg-[#2196f3] -z-[1] opacity-[0.2] left-[10%] bottom-[10%] fixed w-[200px] h-[200px] blur-[100px] rounded-full" />
    </div>
  );
};
