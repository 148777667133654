import { useState } from "react";
import { Button } from "./Button";
import { StakeModal } from "./StakeModal";
import "react-responsive-modal/styles.css";
import { ClaimModal } from "./ClaimModal";

// StakingRow.js
interface StakingRowProps {
  image: string;
  token: string;
  stakedAmount: string;
  balance: string;
  rewardAmount: string;
  stakedTime: string;
  symbol: string;
  buttonLabel: string;
  buttonVariant: string;
}

export const StakingRow = (props: StakingRowProps) => {
  const [stakeOpen, setStakeOpen] = useState<boolean>(false);
  const onStakeOpenModal = () => setStakeOpen(true);
  const onStakeCloseModal = () => setStakeOpen(false);

  const [claimOpen, setClaimOpen] = useState<boolean>(false);
  const onClaimOpenModal = () => setClaimOpen(true);
  const onClaimCloseModal = () => setClaimOpen(false);

  return (
    <div className="group flex w-full items-center justify-between text-[#999999] font-['Trenda_Semibold'] hover:bg-[#fafafa] hover:shadow-lg rounded-[4px] py-[10px] px-[15px]">
      {stakeOpen && (
        <StakeModal open={stakeOpen} onCloseModal={onStakeCloseModal} />
      )}
      {claimOpen && (
        <ClaimModal open={claimOpen} onCloseModal={onClaimCloseModal} />
      )}
      <div className="w-[25%] flex items-center gap-[15px] text-[20px]">
        <img src={props.image} width={40} height={40} alt="" />
        <div>
          <div className="flex gap-[5px] items-center">
            {props.token}
            <Button
              className="flex opacity-[0.1] group-hover:opacity-[1] text-[12px] px-[4px] py-0"
              label="buy."
              variant="button-green"
              onClick={onStakeOpenModal}
            />
          </div>
          <div className="text-[12px] ">
            {props.balance} {props.symbol}
          </div>
        </div>
      </div>

      <div className="w-[30%]">
        <div className="relative text-[22px] font-['Trenda_Bold'] flex flex-col">
          <label className="text-[12px] text-[#999999] font-['Trenda_Semibold']">
            staked amount.
          </label>
          <div className="flex items-center gap-[5px]">
            {props.stakedAmount}
            {props.buttonLabel === "claim." && (
              <Button
                className="flex opacity-[0.1] group-hover:opacity-[1] text-[12px] px-[4px] py-0"
                label="stake more."
                variant="button-green"
                onClick={onStakeOpenModal}
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-[25%]">
        <div className="relative text-[22px] text-[#009688] font-['Trenda_Bold'] flex flex-col">
          <label className="text-[12px] text-[#999999] font-['Trenda_Semibold']">
            total reward.
          </label>
          <div className="flex items-center gap-[5px]">
            {props.rewardAmount === "0.00" ? (
              <div className="text-[#cccccc]">{props.rewardAmount}</div>
            ) : (
              props.rewardAmount
            )}
            {props.buttonLabel === "claim." && (
              <Button
                className="flex opacity-[0.1] group-hover:opacity-[1] text-[12px] px-[4px] py-0"
                label="claim and unstake."
                variant="button-gray"
                onClick={onStakeOpenModal}
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-[20%]">
        <Button
          label={props.buttonLabel}
          variant={props.buttonVariant}
          onClick={
            props.rewardAmount === "0.00" ? onStakeOpenModal : onClaimOpenModal
          }
        />
      </div>
    </div>
  );
};
