export const Footer = () => {
  return (
    <footer className="py-[60px] px-[15px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center text-[#999999] gap-[30px]">
          <div className="cursor-pointer hover:text-[black] font-['Trenda_Semibold'] text-[18px]">
            about us.
          </div>
          <div className="cursor-pointer hover:text-[black] font-['Trenda_Semibold'] text-[18px]">
            user agreements.
          </div>
          <div className="cursor-pointer hover:text-[black] font-['Trenda_Semibold'] text-[18px]">
            advertisment.
          </div>
          <div className="cursor-pointer hover:text-[black] font-['Trenda_Semibold'] text-[18px]">
            contact us.
          </div>
        </div>
        <div className="flex justify-between items-center gap-[10px] cursor-pointer hover:filter hover:brightness-0">
          <div>
            <img width={20} src="/icons/facebook.svg" alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};
