import { useMetaTags } from "react-metatags-hook";
import { StakingRow } from "../components/StakingRow";
import { useAccount } from "wagmi";
import { PromotionStakingRow } from "../components/PromotionStakingRow";
import { PromotionExploreRow } from "../components/PromotionExploreRow";

// Home.js
export const Home = () => {
  useMetaTags(
    {
      title: "stake.",
      description: "stake.",
    },
    []
  );

  const { isConnected } = useAccount();

  return (
    <div
      className={`flex flex-col relative justify-center gap-[15px] ${
        !isConnected ? "blur-[6px] opacity-[0.6]" : ""
      }`}
    >
      {!isConnected && (
        <div className="absolute z-[1] top-0 left-0 w-full h-full" />
      )}

      {isConnected && (
        <PromotionExploreRow
          image="https://assets.coingecko.com/coins/images/28205/standard/Sei_Logo_-_Transparent.png"
          token="SEI"
          stakedAmount="0"
          balance="0"
          rewardAmount="public sale"
          stakedTime="september 16, 2024"
          buttonVariant="button-orange"
          buttonLabel="explore."
          border="#ff5722"
        />
      )}

      <StakingRow
        image="https://assets.coingecko.com/markets/images/429/large/sc-4096x4096.png"
        token="Stake"
        symbol="STK"
        stakedAmount="5,000"
        balance="241"
        rewardAmount="680"
        stakedTime="32 days."
        buttonVariant="button-green"
        buttonLabel="claim."
      />

      <StakingRow
        image="https://assets.coingecko.com/coins/images/17980/standard/ton_symbol.png"
        token="Toncoin"
        symbol="TON"
        stakedAmount="2,450"
        balance="5,000"
        rewardAmount="128"
        stakedTime="54 days."
        buttonVariant="button-green"
        buttonLabel="claim."
      />
      <StakingRow
        image="https://assets.coingecko.com/coins/images/5/standard/dogecoin.png"
        token="Dogecoin"
        symbol="DOGE"
        stakedAmount="1,000,000"
        balance="14,000,000"
        rewardAmount="60,000"
        stakedTime="32 days."
        buttonVariant="button-green"
        buttonLabel="claim."
      />
      <StakingRow
        image="https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png"
        token="Avalanche"
        symbol="AVAX"
        stakedAmount="180"
        balance="1,540"
        rewardAmount="54"
        stakedTime="19 days."
        buttonVariant="button-green"
        buttonLabel="claim."
      />
      {isConnected && (
        <PromotionStakingRow
          image="https://assets.coingecko.com/coins/images/13397/standard/Graph_Token.png"
          token="The Graph"
          symbol="GRP"
          stakedAmount="0"
          balance="8,000"
          rewardAmount="240% APY"
          stakedTime="120 days."
          buttonVariant="button-blue"
          buttonLabel="stake."
          border="#ff5722"
        />
      )}
      <StakingRow
        image="https://assets.coingecko.com/coins/images/11939/standard/shiba.png"
        token="Shiba Inu"
        symbol="SHIB"
        stakedAmount="0"
        balance="19,000,000"
        rewardAmount="0"
        stakedTime="not staked yet."
        buttonVariant="button-blue"
        buttonLabel="stake."
      />
      <StakingRow
        image="https://assets.coingecko.com/coins/images/877/standard/chainlink-new-logo.png"
        token="Chainlink"
        symbol="LINK"
        stakedAmount="0"
        balance="680"
        rewardAmount="0"
        stakedTime="not staked yet."
        buttonVariant="button-blue"
        buttonLabel="stake."
      />
    </div>
  );
};
