import { useState } from "react";
import { Button } from "./Button";
import { StakeModal } from "./StakeModal";
import "react-responsive-modal/styles.css";
import { ClaimModal } from "./ClaimModal";

// PromotionExploreRow.js
interface PromotionExploreRowProps {
  image: string;
  token: string;
  stakedAmount: string;
  balance: string;
  rewardAmount: string;
  stakedTime: string;
  buttonLabel: string;
  buttonVariant: string;
  border: string;
}

export const PromotionExploreRow = (props: PromotionExploreRowProps) => {
  const [stakeOpen, setStakeOpen] = useState<boolean>(false);
  const onStakeOpenModal = () => setStakeOpen(true);
  const onStakeCloseModal = () => setStakeOpen(false);

  const [claimOpen, setClaimOpen] = useState<boolean>(false);
  const onClaimOpenModal = () => setClaimOpen(true);
  const onClaimCloseModal = () => setClaimOpen(false);

  return (
    <div
      className={`relative group flex w-full items-center justify-between text-[#999999] font-['Trenda_Semibold'] hover:bg-[#fafafa] hover:shadow-lg rounded-[4px] px-[15px] py-[15px] mt-[15px] border-[2px] border-dashed hover:border-solid hover:border-[#ff5722] border-[#999999]`}
    >
      <div className="flex gap-[5px] items-center absolute -top-[21px] -left-[2px] text-[14px] px-[10px] rounded-b-none rounded-[4px] bg-[#999999] group-hover:bg-[#ff5722] text-white">
        advertorial <img src="/icons/question-mark.svg" width={12} alt="" />
      </div>
      {stakeOpen && (
        <StakeModal open={stakeOpen} onCloseModal={onStakeCloseModal} />
      )}
      {claimOpen && (
        <ClaimModal open={claimOpen} onCloseModal={onClaimCloseModal} />
      )}
      <div className="w-[25%] flex items-center gap-[15px] text-[20px]">
        <img src={props.image} width={40} height={40} alt="" />
        {props.token}
      </div>

      <div className="w-[30%]">
        <div className="text-[22px] text-[#ff5722]">{props.rewardAmount}</div>
      </div>

      <div className="w-[25%]">
        <div className="text-[22px] text-[#ff5722]">{props.stakedTime}</div>
      </div>

      <div className="w-[20%]">
        <Button
          label={props.buttonLabel}
          variant={props.buttonVariant}
          onClick={
            props.rewardAmount === "0.00" ? onStakeOpenModal : onClaimOpenModal
          }
        />
      </div>
    </div>
  );
};
