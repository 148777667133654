// NotFound.js
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useMetaTags } from "react-metatags-hook";

export const NotFound = () => {
  useMetaTags(
    {
      title: "Sayfa Bulunamadı - Cronwork Dijital Ajans",
      description:
        "Firmalar için yazılım geliştirme desteği sunuyoruz. Blockchain, web app, crm ve bayi paneli çözümleri.",
    },
    []
  );

  return (
    <div className="mainContainer bg-brown/60">
      <div className="text-white flex justify-between flex-col min-h-[100dvh] px-[30px] py-[80px] sm:px-[60px] sm:py-[60px] md:px-[100px] md:py-[40px] lg:px-[160px] lg:py-[80px]">
        <Header />
        <div className="pb-[150px]">
          <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
            sayfa bulunamadı
            <br />
            <u className="bg-brown bg-opacity-60 font-bold">404</u>
          </h2>
        </div>
        <Footer />
      </div>
    </div>
  );
};
