import { useMetaTags } from "react-metatags-hook";
import Slider from "react-slick";
import { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Page.js
interface PageProps {
  type: string;
  color: string;
}

// Page.js
export const Page = (props: PageProps) => {
  const title = props.type;
  const color = props.color;

  useEffect(() => {
    if (document.getElementsByClassName("mainContainer").length > 0)
      document.getElementsByClassName(
        "mainContainer"
      )[0].className = `mainContainer bg-${color} bg-opacity-60`;
  }, [color]);

  const logos: any = {
    "blockchain çözümleri": [
      <img
        className="mx-auto"
        width={120}
        src="/logos/nuklai.png"
        alt="Nuklai"
      />,
      <img
        className="mt-[18px] mx-auto"
        width={120}
        src="/logos/nexera.png"
        alt="Nexera"
      />,
      <img
        className="mt-[10px] mx-auto"
        width={140}
        src="/logos/theunfettered.png"
        alt="The Unfettered"
      />,
      <img
        className="mt-[15px] mx-auto"
        width={180}
        src="/logos/walletscan.png"
        alt="Walletscan"
      />,
      <img
        className="mt-[10px] mx-auto"
        width={180}
        src="/logos/allianceblock.png"
        alt="AllianceBlock"
      />,
    ],

    "internet sitesi ve eticaret": [
      <img
        className="mt-[4px] mx-auto"
        width={150}
        src="/logos/wepro.png"
        alt="Wepro"
      />,
      <img
        className="mt-[4px] mx-auto"
        width={150}
        src="/logos/seogum.png"
        alt="Seogum"
      />,
      <img
        className="mx-auto"
        width={140}
        src="/logos/wantuz.png"
        alt="Wantuz"
      />,
      <img
        className="mt-[4px] mx-auto"
        width={150}
        src="/logos/yeninesilmenu.png"
        alt="Yeni Nesil Menü"
      />,
    ],

    "üçüncü parti danışmanlık": [
      <img
        className="mt-[12px] mx-auto"
        width={160}
        src="/logos/manypoints.png"
        alt="ManyPoints"
      />,
      <img
        className="mt-[5px] mx-auto"
        width={190}
        src="/logos/mertyazilim.png"
        alt="Mert Yazılım"
      />,
      <img
        className="mx-auto"
        width={100}
        src="/logos/bravoworks.png"
        alt="BravoWorks"
      />,
      <img
        className="mx-auto"
        width={180}
        src="/logos/zenacreative.png"
        alt="Zena Creative"
      />,
      <img
        className="mt-[15px] mx-auto"
        width={180}
        src="/logos/arkaplan.png"
        alt="Arkaplan"
      />,
    ],

    "kapalı devre çözümler": [
      <img
        className="mx-auto"
        width={150}
        src="/logos/alpeda.png"
        alt="Alpeda"
      />,
      <img
        className="mx-auto"
        width={150}
        src="/logos/pakkens.png"
        alt="Pakkens"
      />,
      <img
        className="mx-auto"
        width={150}
        src="/logos/yilkarklima.png"
        alt="Yılkar Klima"
      />,
    ],

    "otomasyon sistemleri": [
      <img
        className="mx-auto"
        width={100}
        src="/logos/yeditek.png"
        alt="Yeditek"
      />,
      <img
        className="mx-auto"
        width={150}
        src="/logos/alpeda.png"
        alt="Alpeda"
      />,
      <img
        className="mx-auto"
        width={150}
        src="/logos/pakkens.png"
        alt="Pakkens"
      />,
    ],
  };

  const whatWeDoing: any = {
    "blockchain çözümleri":
      "blockchain çözümleri konusunda, müşterilerimize dopp geliştirme, cüzdan etkileşimleri ve smart contract oluşturma hizmetleri sunuyoruz. on-chain veri yönetimi, token oluşturma ve nft üretimi ile ilgili projelerde de uzmanlaşmış durumdayız. blockchain tabanlı çözümlerimizle, firmaların dijital dönüşüm süreçlerini hızlandırmalarına yardımcı oluyoruz.",
    "internet sitesi ve eticaret":
      "internet sitesi ve eticaret geliştirme konusunda, müşterilerimize ihtiyaçlarına özel çözümleri sunuyoruz. web app ve startup projeleri için yenilikçi ve ölçeklenebilir çözümler üretiyoruz. api entegrasyonları ile özelleşmiş çözümler üretebiliyoruz. seo çalışması ile google'da bulunabilirliğinizi yükseltiyoruz.",
    "üçüncü parti danışmanlık":
      "üçüncü parti danışmanlık hizmetlerimizle, web yazılım desteği ve projelendirme süreçlerinde size yardımcı oluyoruz. panel geliştirme ve sunucu yönetimi konularında kapsamlı çözümler sunuyoruz. hosting ve e-posta hizmetlerimizle, işletmenizin tüm dijital ihtiyaçlarını karşılıyoruz.",
    "kapalı devre çözümler":
      "kapalı devre çözümler kapsamında, kiosk ve bekçi turlama sistemi gibi özel sistemler geliştiriyoruz. ürün giriş-çıkış takibi ve stok takip sistemleri ile envanter yönetimini optimize ediyoruz. dijital menü, ekran yazılımları ve nfc yazılımları ile iş süreçlerinizi dijitalleştiriyoruz.",
    "otomasyon sistemleri":
      "otomasyon sistemleri alanında, gelişmiş görüntü işleme ve hassas makine kalibrasyon hizmetleri sunuyoruz. uyarı sistemlerimizle işletme güvenliğini artırırken, raporlama çözümleriyle performansınızı izleyip optimize ediyoruz. size özel, entegre otomasyon çözümleri geliştirerek iş süreçlerinizi iyileştiriyoruz.",
  };

  const yourPartner: any = {
    "blockchain çözümleri":
      "cronwork olarak, blockchain teknolojisi ile iş süreçlerinizi optimize ediyor ve verimliliği artırıyoruz. işletmenizin ihtiyaçlarına uygun, ölçeklenebilir ve güvenli yazılım çözümleri sağlıyoruz. yazılım geliştirme ortağınız olarak, projelerinizi hayata geçirirken en son teknolojileri kullanıyoruz.",
    "internet sitesi ve eticaret":
      "cronwork olarak, internet sitesi ve eticaret geliştirme süreçlerinde yanınızda yer alıyoruz. işletmenizin hedeflerine uygun, kullanıcı dostu ve güvenli yazılım çözümleri sunuyoruz. yazılım geliştirme ortağınız olarak, projelerinizde en yeni teknolojileri kullanarak rekabet avantajı sağlamanıza yardımcı oluyoruz.",
    "üçüncü parti danışmanlık":
      "cronwork olarak, üçüncü parti danışmanlık hizmetlerinde uzmanız. işletmenizin ihtiyaçlarına yönelik, ölçeklenebilir ve güvenilir çözümler üretiyoruz. projelerinizin her aşamasında yanınızda olup, iş süreçlerinizi optimize ediyoruz.",
    "kapalı devre çözümler":
      "cronwork olarak, kapalı devre çözümlerinizde size özgün ve etkili çözümler sunuyoruz. işletmenizin ihtiyaçlarına göre, esnek ve entegre yazılım sistemleri oluşturuyoruz. projelerinizin başarısı için en son teknolojileri kullanarak, iş verimliliğinizi artırıyoruz.",
    "otomasyon sistemleri":
      "cronwork olarak, otomasyon sistemleri projelerinizde profesyonel destek sağlıyoruz. görüntü işleme ve makine kalibrasyon alanlarında yenilikçi teknolojiler kullanıyoruz. uyarı ve raporlama sistemlerimizle, operasyonlarınızın güvenli ve verimli olmasını sağlıyoruz.",
  };

  const products: any = {
    "blockchain çözümleri": [
      ["dapp geliştirme", "evm platformlar"],
      ["cüzdan etkileşimleri", "metamask, walletconnect vb."],
      ["smart contract", "solidity ve audit"],
      ["on-chain veri yönetimi", "veri yazma ve okuma"],
      ["token oluşturma", "launch pad ve icolar"],
      ["nft üretimi ve etkileşimler", "koleksiyonlar"],
    ],
    "internet sitesi ve eticaret": [
      ["internet sitesi", "kurumsal, landing vb."],
      ["eticaret sitesi", "tek sayfa, geleneksel, gelişmiş"],
      ["özel proje", "web tabanlı özel arayüz ve panel"],
      ["seo", "arama sonuçlarında ilk sıralar"],
      ["web app", "amaca yönelik eticaret geliştirme"],
      ["startup", "ödeme sistemleri ve abonelikler"],
      ["api server", "api oluşturma ve limitler"],
    ],
    "üçüncü parti danışmanlık": [
      ["web yazılım desteği", "internet sitesi, eticaret"],
      ["danışmanlık", "dijital süreçler ve yönetimi"],
      ["panel geliştirme", "bayi ve müşteri portalları"],
      ["projelendirme", "özel talepler için destek"],
      ["sunucu, hosting, eposta", "sunucu yönetimi ve teknik destek"],
    ],
    "kapalı devre çözümler": [
      ["kiosk", "veri toplama ve sunum ekranları"],
      ["bekçi turlama sistemi", "qr kod ile güvenlik takibi"],
      ["ürün giriş çıkış takibi", "kargo ve paketleme takip sistemleri"],
      ["stok takip sistemleri", "barkodlama ve stok hareketleri"],
      ["dijital menü", "qr kod ile menü ve ödeme sistemleri"],
      ["ekran yazılımları", "menü ve reklam sistemleri"],
      ["nfc yazılımları", "kontör ve sadakat kart sistemleri"],
    ],
    "otomasyon sistemleri": [
      ["görüntü işleme", "kamera ile hata tespit"],
      ["makine kalibrasyon", "algoritma ile anlık makine kontrolü"],
      ["uyarı sistemleri", "sensöre dayalı uyarı sistemleri"],
      ["raporlama", "uyarlanabilir sistem raporları"],
    ],
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: Object.values(logos[props.type]).length,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const metaTitle: any = {
    "blockchain çözümleri": "Blockchain Çözümleri - Cronwork Dijital Ajans",
    "internet sitesi ve eticaret":
      "İnternet Sitesi ve Uygulama - Cronwork Dijital Ajans",
    "üçüncü parti danışmanlık": "Üçüncü Parti Danışmanlık",
    "kapalı devre çözümler": "Kapalı Devre Çözümler - Cronwork Dijital Ajans",
    "otomasyon sistemleri": "Otomasyon Sistemleri - Cronwork Dijital Ajans",
  };

  const metaDescription: any = {
    "blockchain çözümleri":
      "Blockchain teknolojisiyle işinizi geleceğe taşıyan Cronwork, güvenli ve ölçeklenebilir yazılım çözümleri sunar.",
    "internet sitesi ve eticaret":
      "Cronwork, internet sitesi ve eticaret geliştirme süreçlerinde modern teknolojilerle projelerinizi güçlendirir ve verimliliği artırır.",
    "üçüncü parti danışmanlık":
      "Cronwork, üçüncü parti danışmanlık hizmetlerinde uzman. İşletmenizin ihtiyaçlarına uygun güvenilir çözümlerle projelerinizde yanınızdayız.",
    "kapalı devre çözümler":
      "Kapalı devre çözümlerle dijital menü ve ekran yazılımları geliştiriyoruz. Stok ve ürün takibi ile iş süreçlerinizi dijitalleştiriyoruz.",
    "otomasyon sistemleri":
      "Cronwork, entegre otomasyon çözümleriyle iş süreçlerinizi iyileştirir. Uyarı sistemleri ve raporlama çözümleriyle performansınızı artırır.",
  };

  useMetaTags(
    {
      title: metaTitle[props.type],
      description: metaDescription[props.type],
    },
    []
  );

  return (
    <div className="my-[100px]">
      <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
        {title}
      </h2>
      <div className="leading-[28px] text-justify text-[18px] sm:!text-[22px] flex flex-col gap-[30px]">
        <strong className="font-['Trenda_Semibold'] text-[22px] md:text-[28px] leading-[1.6]">
          neler yapıyoruz
        </strong>
        <p>{whatWeDoing[props.type]}</p>
        <Slider {...settings} className="my-[30px]">
          {logos[props.type] &&
            Object.values(logos[props.type]).map((item: any, index: number) => (
              <div key={index}>{item}</div>
            ))}
        </Slider>
        <strong className="font-['Trenda_Semibold'] text-[22px] md:text-[28px] leading-[1.6]">
          yazılım geliştirme ortağınız
        </strong>
        <p>{yourPartner[props.type]}</p>
        <strong className="font-['Trenda_Semibold'] text-[22px] md:text-[28px] leading-[1.6]">
          ürünler
        </strong>
        <ul className="w-full font-['Trenda_Semibold'] text-[22px] md:text-[22px] lg:text-[24px] leading-[1.6] tracking-[2px] flex flex-col">
          {logos[props.type] &&
            Object.values(products[props.type]).map(
              (item: any, index: number) => (
                <li
                  key={index}
                  className="cursor-default hover:opacity-[1] opacity-[0.5]"
                >
                  <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
                    <span>{item[0]}</span>
                    <u className={`bg-${color} bg-opacity-60`}>{item[1]}</u>
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
    </div>
  );
};
