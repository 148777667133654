import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

// Button.js
interface ButtonProps {
  label?: string;
  variant: string;
  url?: string;
  fullWidth?: boolean;
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
  const variantClass: any = {
    "button-blue":
      "rounded-[4px] border-[2px] border-[#2196f3] bg-[#2196f3] text-white hover:bg-transparent hover:text-[#2196f3] transition-all",

    "button-green":
      "rounded-[4px] border-[2px] border-[#009688] bg-[#009688] text-white hover:bg-transparent hover:text-[#009688] transition-all",

    "button-orange":
      "rounded-[4px] border-[2px] border-[#ff5722] bg-[#ff5722] text-white hover:bg-transparent hover:text-[#ff5722] transition-all",

    "button-gray":
      "rounded-[4px] border-[2px] border-[#999999] bg-[#999999] text-white hover:bg-transparent hover:text-[#999999] transition-all",

    link: "text-black !px-0 !py-0 hover:underline",
  };
  return (
    <Link
      onClick={props.onClick}
      to={props.url ? props.url : "#"}
      className={twMerge(
        "text-[24px] flex gap-[5px] items-center transition-all px-[12px] py-[4px] font-['Trenda_Bold'] justify-center items-center whitespace-nowrap",
        props.className,
        props.fullWidth && "w-auto flex-grow",
        variantClass[props.variant]
      )}
    >
      {props.icon}
      {props.label}
    </Link>
  );
};
