import { useMetaTags } from "react-metatags-hook";
import { Outlet } from "react-router-dom";

// Dashboard.js
export const Dashboard = () => {
  useMetaTags(
    {
      title: "Wepro",
      description: "Wepro",
    },
    []
  );

  return (
    <div>
      <Outlet />
    </div>
  );
};
