import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useSwitchChain,
} from "wagmi";
import { useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export const Header = () => {
  const { chainId, address, isConnected } = useAccount();
  const { connectors, connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });

  const { switchChain } = useSwitchChain();

  useEffect(() => {
    if (chainId && isConnected && chainId !== 11155420)
      switchChain({ chainId: 11155420 });
  }, [chainId, switchChain, isConnected]);

  const items = [
    {
      id: "STK",
      name: "Stake - STK",
    },
    {
      id: "ETH",
      name: "Ethereum - ETH",
    },
    {
      id: "NAI",
      name: "Nuklai - NAI",
    },
  ];

  type Item = {
    id: string;
    name: string;
  };

  const handleOnSearch = (string: string, results: Item[]) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item: Item) => {
    return (
      <div className="text-[#009688] w-full font-['Trenda_Semibold'] flex justify-between items-center pl-[36px] pr-[40px]">
        <div>{item.name}</div>
        <div className="font-['Trenda_Bold'] text-[14px] flex">
          <div className="flex gap-[5px] items-center font-['Trenda_Semibold'] text-[#009688] p-[10px] rounded-[4px]">
            <span className="text-[#000000]">Staked</span> 432,030 NAI
          </div>
          <div className="flex gap-[5px] items-center font-['Trenda_Semibold'] text-[#009688] p-[10px] rounded-[4px]">
            <span className="text-[#000000]">Rewards</span> 1,598,970 NAI
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="py-[60px] px-[15px]">
      <div className="flex justify-between items-center gap-[30px]">
        <div className="font-['Trenda_Bold] text-[52px] leading-[1] text-[#666666] relative flex">
          stake.
        </div>
        <div className="relative grow flex">
          <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            placeholder="search your stakable token"
            styling={{
              borderRadius: "4px",
              border: "none",
              hoverBackgroundColor: "#eee",
              boxShadow: "rgba(0, 0, 0, 0.1) 5px 5px 10px -2px",
              placeholderColor: "#ccc",
              lineColor: "#ffffff",
            }}
            className="!outline-none w-full  focus:shadow rounded-[4px]"
          />
        </div>
        <div className="text-right text-[#666666]">
          {isConnected ? (
            <div className="flex gap-[10px] items-center">
              <div className="flex gap-[5px] items-center font-['Trenda_Semibold']  text-[#000000] p-[10px] rounded-[4px]">
                <img
                  src="https://assets.coingecko.com/coins/images/279/standard/ethereum.png"
                  width={24}
                  alt=""
                />
                0.004 ETH
              </div>
              <div className="flex gap-[5px] items-center font-['Trenda_Semibold']  text-[#009688] p-[10px] rounded-[4px]">
                <img
                  src="https://assets.coingecko.com/markets/images/429/large/sc-4096x4096.png"
                  width={24}
                  alt=""
                />
                5,921 STK
              </div>
              <div className="flex gap-[5px] cursor-pointer hover:shadow items-center h-[44px] font-['Trenda_Semibold'] bg-[#fafafa] shadow-lg  text-[#000000] p-[10px] pl-[15px] pr-[5px] justify-between rounded-[4px]">
                <img src="/icons/optimism-sepolia.svg" width={90} alt="" />
                <img src="/icons/caret-down.svg" width={24} alt="" />
              </div>
              <button
                className="group text-[white] rounded-[4px] h-[44px] bg-[#ffffff] hover:shadow flex items-center shadow-lg gap-[10px] px-[15px] py-[10px] font-['Trenda_Bold']"
                onClick={() => disconnect()}
              >
                <img
                  src={connectors[0].icon}
                  width={20}
                  className="drop-shadow-[0_0_8px_rgba(255,255,255,1)]"
                  alt=""
                />
                {/* {ensName
                  ? `${ensName} (${address})`
                  : `${address?.substring(0, 6)} ... ${address?.substring(
                      address?.length - 6
                    )}`}
                <img
                  src="/icons/logout.svg"
                  width={20}
                  className="drop-shadow-[0_0_8px_rgba(255,255,255,1)]"
                  alt=""
                /> */}
              </button>
            </div>
          ) : (
            connectors.map((connector) => (
              <button
                className="bg-[#fafafa] group text-[#999999] hover:text-[white] rounded-[4px] shadow-lg hover:bg-[#f5841f] flex items-center gap-[10px] px-[20px] py-[10px] font-['Trenda_Bold']"
                key={connector.uid}
                onClick={() => connect({ connector })}
              >
                <img
                  src={connector.icon}
                  width={20}
                  className="drop-shadow-[0_0_8px_rgba(255,255,255,1)]"
                  alt=""
                />
                connect your wallet.
              </button>
            ))
          )}
        </div>
      </div>
    </header>
  );
};
