// App.js
import { HashRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Home } from "./pages/Home";
import { Page } from "./components/Page";
import { NotFound } from "./components/NotFound";
import { Dashboard } from "./components/Dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./config";

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <HashRouter basename="/">
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/hakkimizda" element={<Home />} />
              <Route path="/iletisim" element={<Home />} />
              <Route
                path="/blockchain-cozumleri"
                element={<Page type="blockchain çözümleri" color="black" />}
              />
              <Route path="/dashboard/" element={<Dashboard />} />
            </Route>
          </Routes>
        </HashRouter>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
