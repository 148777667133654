import { useState } from "react";
import { Button } from "./Button";
import { StakeModal } from "./StakeModal";
import "react-responsive-modal/styles.css";
import { ClaimModal } from "./ClaimModal";

// PromotionStakingRow.js
interface PromotionStakingRowProps {
  image: string;
  token: string;
  symbol: string;
  stakedAmount: string;
  balance: string;
  rewardAmount: string;
  stakedTime: string;
  buttonLabel: string;
  buttonVariant: string;
  border: string;
}

export const PromotionStakingRow = (props: PromotionStakingRowProps) => {
  const [stakeOpen, setStakeOpen] = useState<boolean>(false);
  const onStakeOpenModal = () => setStakeOpen(true);
  const onStakeCloseModal = () => setStakeOpen(false);

  const [claimOpen, setClaimOpen] = useState<boolean>(false);
  const onClaimOpenModal = () => setClaimOpen(true);
  const onClaimCloseModal = () => setClaimOpen(false);

  return (
    <div
      className={`relative group flex w-full items-center justify-between text-[#999999] font-['Trenda_Semibold'] hover:bg-[#fafafa] hover:shadow-lg rounded-[4px] px-[15px] py-[15px] mt-[15px] border-[2px] border-dashed hover:border-solid hover:border-[#2196f3] border-[#999999]`}
    >
      <div className="flex gap-[5px] items-center absolute -top-[21px] -left-[2px] text-[14px] px-[10px] rounded-b-none rounded-[4px] bg-[#999999] group-hover:bg-[#2196f3] text-white">
        advertorial <img src="/icons/question-mark.svg" width={12} alt="" />
      </div>
      {stakeOpen && (
        <StakeModal open={stakeOpen} onCloseModal={onStakeCloseModal} />
      )}
      {claimOpen && (
        <ClaimModal open={claimOpen} onCloseModal={onClaimCloseModal} />
      )}
      <div className="w-[25%] flex items-center gap-[15px] text-[20px]">
        <img src={props.image} width={40} height={40} alt="" />
        <div>
          <div className="flex gap-[5px] items-center">
            {props.token}
            <Button
              className="flex opacity-[0.1] group-hover:opacity-[1] text-[12px] px-[4px] py-0"
              label="buy."
              variant="button-green"
              onClick={onStakeOpenModal}
            />
          </div>
          <div className="text-[12px] ">
            {props.balance} {props.symbol}
          </div>
        </div>
      </div>

      <div className="w-[30%]">
        <div className="text-[22px] text-[#2196f3] font-['Trenda_Bold']">
          {props.rewardAmount}
        </div>
      </div>

      <div className="w-[25%]">
        <div className="text-[26px] text-[#2196f3] font-['Trenda_Bold']">
          {props.stakedTime}
        </div>
      </div>

      <div className="w-[20%]">
        <Button
          label={props.buttonLabel}
          variant={props.buttonVariant}
          onClick={onStakeOpenModal}
        />
      </div>
    </div>
  );
};
